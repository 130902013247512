<template>
  <div
    v-if="$store.state.auth.role === 1"
  >
    <b-card no-body>
      <div
        class="mb-0"
      >
        <b-row
          class="m-2 d-flex justify-content-between"
        >
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-form class="w-100">
                <b-input-group>
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Recherche..."
                  />
                </b-input-group>
              </b-form>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-link
                :to="{ name: 'users-create' }"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Ajouter un utilisateur
                </b-button>
              </b-link>
            </div>
          </b-col>
        </b-row>

        <b-table
          v-if="usersList.length > 0"
          class="position-relative"
          :items="usersList.slice(
            (currentPage - 1) * perPage,
            currentPage * perPage,
          )"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Aucun utilisateur trouvé"
        >
          <template #cell(username)="data">
            <b-link
              :to="{ name: 'users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block link-hover"
            >
              {{ data.item.username }}
            </b-link>
          </template>

          <template #cell(firstname)="data">
            <small>
              {{ data.item.firstname }}
            </small>
          </template>

          <template #cell(lastname)="data">
            <small>
              {{ data.item.lastname }}
            </small>
          </template>

          <template #cell(enterprise)="data">
            <small>
              {{ data.item.enterprise }}
            </small>
          </template>

          <template #cell(scopes)="data">
            <small v-if="data.item.scopes.includes('superadmin')">
              <b-avatar
                v-b-tooltip.hover
                title="Superadmin"
                :variant="`light-${resolveScopeVariant('superadmin')}`"
              >
                S
              </b-avatar>
            </small>
            <small v-if="!data.item.scopes.includes('superadmin') && data.item.scopes.includes('admin')">
              <b-avatar
                v-b-tooltip.hover
                title="Admin"
                :variant="`light-${resolveScopeVariant('admin')}`"
              >
                A
              </b-avatar>
            </small>
            <small v-if="!data.item.scopes.includes('superadmin') && !data.item.scopes.includes('admin') && data.item.scopes.includes('user')">
              <b-avatar
                v-b-tooltip.hover
                title="User"
                :variant="`light-${resolveScopeVariant('user')}`"
              >
                U
              </b-avatar>
            </small>
          </template>

          <template #cell(channel)="data">
            <div
              v-if="typeof data.item.channel === 'object'"
            >
              <div
                v-for="channel, indChannel in data.item.channel"
                :key="indChannel"
              >
                <b-badge
                  :variant="`light-${resolveChannelVariant(channel)}`"
                  pill
                >
                  {{ channel }}
                </b-badge>
              </div>
            </div>
            <div
              v-else
            >
              <b-badge
                :variant="`light-${resolveChannelVariant(data.item.channel)}`"
                pill
              >
                {{ data.item.channel }}
              </b-badge>
            </div>
          </template>
        </b-table>
        <div
          v-else
          class="text-center"
        >
          <b-spinner
            v-if="isLoading"
            class="mt-5 mb-5"
          />
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ dataMeta.from }} à {{ dataMeta.to }} sur {{ dataMeta.of }} utilisateurs</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BLink, BSpinner, VBTooltip,
  BFormInput, BPagination, BBadge,
  BForm, BInputGroup, BButton, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BSpinner,
    BFormInput,
    BForm,
    BInputGroup,
    BButton,
    BPagination,
    BAvatar,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      usersList: [],
      usersListTotal: [],
      totalUsers: null,
      tableColumns: [
        { key: 'username', label: 'Utilisateur' },
        { key: 'firstname', label: 'Prénom' },
        { key: 'lastname', label: 'Nom' },
        { key: 'enterprise', label: 'Entreprise' },
        {
          key: 'scopes', label: 'Scopes', tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'channel', label: 'Channel', tdClass: 'text-center', thClass: 'text-center',
        },
      ],
      perPage: 20,
      currentPage: 1,
      searchQuery: null,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.usersList.length ? this.usersList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      ).length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
  },
  watch: {
    currentPage(newData) {
      this.currentPage = newData
      return this.usersList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      )
    },
    searchQuery(newData) {
      this.currentPage = 1
      this.searchQuery = newData.toLowerCase()
      this.searchUser()
    },
  },
  created() {
    setTimeout(this.getUsers, 1000)
  },
  methods: {
    getUsers() {
      this.isLoading = true
      store.dispatch('user/findAllUsers')
        .then(response => {
          response.forEach(user => {
            // eslint-disable-next-line no-param-reassign
            user.channel = JSON.parse(atob(user.preferences)).channel
          })
          this.usersList = response.sort((a, b) => a.username.localeCompare(b.username))
          this.usersListTotal = response.sort((a, b) => a.username.localeCompare(b.username))
          this.totalUsers = response.length
          this.currentPage = 1
          this.isLoading = false
        })
        .catch(err => {
          console.log('err', err)
          this.isLoading = false
          this.usersList = []
        })
    },
    searchUser() {
      this.currentPage = 1
      this.usersList = this.usersListTotal.filter(user => user.firstname.toLowerCase().includes(this.searchQuery) || user.lastname.toLowerCase().includes(this.searchQuery) || user.enterprise.toLowerCase().includes(this.searchQuery) || user.username.toLowerCase().includes(this.searchQuery))
      this.totalUsers = this.usersList.length
    },
    resolveScopeVariant(data) {
      if (data === 'superadmin') return 'danger'
      if (data === 'admin') return 'info'
      if (data === 'user') return 'success'
      return 'dark'
    },
    resolveChannelVariant(data) {
      if (data === 'prod') return 'primary'
      if (data === 'beta') return 'info'
      return 'dark'
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
